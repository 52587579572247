// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagPanel_tag-list__aTzOn {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/base/tagPanel/TagPanel.module.sass"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,eAAA;EACA,QAAA;AADF","sourcesContent":[".tag-panel\r\n\r\n.tag-list\r\n  display: flex\r\n  flex-wrap: wrap\r\n  gap: 8px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag-list": `TagPanel_tag-list__aTzOn`
};
export default ___CSS_LOADER_EXPORT___;
