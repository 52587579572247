// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CalculationSettings_wrapper__eln4K {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.CalculationSettings_content__zXsLw {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.CalculationSettings_axis__lqA6a {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.CalculationSettings_row__Hl7ZC {
  display: flex;
  gap: 8px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/editor/calculationSettings/CalculationSettings.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAEF;;AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;AAGF;;AADA;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AAIF","sourcesContent":[".wrapper\r\n  display: flex\r\n  flex-direction: column\r\n  gap: 16px\r\n\r\n.content\r\n  display: flex\r\n  flex-direction: column\r\n  gap: 8px\r\n\r\n.axis\r\n  display: flex\r\n  flex-direction: column\r\n  gap: 8px\r\n  margin-bottom: 16px\r\n\r\n.row\r\n  display: flex\r\n  gap: 8px\r\n  align-items: center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CalculationSettings_wrapper__eln4K`,
	"content": `CalculationSettings_content__zXsLw`,
	"axis": `CalculationSettings_axis__lqA6a`,
	"row": `CalculationSettings_row__Hl7ZC`
};
export default ___CSS_LOADER_EXPORT___;
