// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Example_example__WTcBp {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 0 0 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/articles/example/Example.module.sass"],"names":[],"mappings":"AAAA;EACI,uBAAA;EAAA,kBAAA;EACA,mBAAA;AACJ","sourcesContent":[".example\r\n    width: fit-content\r\n    padding: 0 0 0 16px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"example": `Example_example__WTcBp`
};
export default ___CSS_LOADER_EXPORT___;
