import { createBrowserRouter } from "react-router-dom";
import { Main } from "./main/Main";
import { Editor } from "./editor/Editor old";
import { Error } from "./error/Error";
import { Layout } from "./layout/Layout";
import { Articles } from "./articles/Articles";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Main />
      },
      {
        path: "editor",
        element: <Editor />
      },
      {
        path: "articles",
        element: <Articles />
      },
      {
        path: "*",
        element: <Error />
      }
    ]
  },
]);