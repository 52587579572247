import { React, useState, useRef, useEffect } from "react";
import { ViewArea } from "../../components/editor/ViewArea/ViewArea";
import styles from "./Editor.module.sass";
import { LeftPanel } from "../../components/base/leftPanel/LeftPanel";
import { RightPanel } from "../../components/base/rightPanel/RightPanel";

export const Editor = () => {
  const viewAreaRef = useRef();
  const [viewAreaHeight, setViewAreaHeight] = useState(0);

  const onResize = () => {
    setViewAreaHeight(viewAreaRef.current.offsetHeight - 1);
  };

  useEffect(() => {
    setViewAreaHeight(viewAreaRef.current.offsetHeight - 1);
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <main className={styles["main"]}>
      <div className={styles["view-area"]} ref={viewAreaRef}>
        <ViewArea
          height={viewAreaHeight}
          axis
          cameraControl="orbit"
          cameraType="perspective"
        />
      </div>
      
      <LeftPanel />
      <RightPanel />
    </main>
  );
};