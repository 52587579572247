import { React } from "react";
import PropTypes, { element } from "prop-types";
import styles from "../../../assets/styles/components/editor/Editor.module.sass";
import { CanvasArea } from "./Canvas";
import { Icon } from "../../base/icon/Icon";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../stores/root-store-context";


export const ViewArea = observer(({ param = undefined, cameraControl = "orbit", cameraType = "perspective", axis = false, color = null, ...props }) => {
    const {
        projectStore: {
            modelStore: {
                points,
                isModelLoading
            },
            displaySettingsStore: { displayType, displayParameter }
        }
    } = useStores();

    let localStore = window.localStorage.getItem("points") ? JSON.parse(window.localStorage.getItem("points")) : [];

    console.log("RENDER", points);
    let _points =
            points ?
                displayParameter ?
                    points.map(e => { return { ...e, z:e[displayParameter] }; } )
                    : points
                : localStore;

    let groups = [{
        points: _points,
        type: displayType
        }
    ];

    return (
        <div className={styles["viewArea"]} style={{ height: props.height }}>
            <CanvasArea groups={groups} type={displayType} cameraControl={cameraControl} cameraType={cameraType} axis={axis} color={color} />
            { isModelLoading &&
                <div className={styles["loadPanel"]}>
                    <Icon icon="line-md:loading-twotone-loop" rotate={0} size={24} />
                    <span>{isModelLoading}</span>
                </div>
            }
        </div>
    );
});

ViewArea.propTypes = {
    type: PropTypes.oneOf(["line", "points", "mesh", "mesh-full", "lineLevel"]),
    cameraControl: PropTypes.oneOf(["orbit", "trackball"]),
    cameraType: PropTypes.oneOf(["orthographic", "perspective"]),
    axis: PropTypes.bool,
    points: PropTypes.arrayOf(
        PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
            z: PropTypes.number
        })
    ),
    color: PropTypes.string
};
