// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Media parameters */
/* Colors */
/* Fonts */
.Label_label__uWjz7 {
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  color: #8000FF;
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
}

.Label_label-required__u8Pg6::after {
  position: absolute;
  display: block;
  content: "*";
  color: #D60056;
  top: 0;
  right: 0;
  translate: 100% -4px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.sass","webpack://./src/components/form/label/Label.module.sass"],"names":[],"mappings":"AAAA,qBAAA;AASA,WAAA;AAWA,UAAA;ACjBA;EACI,uBAAA;EAAA,kBAAA;EACA,kBAAA;EACA,cDOK;ECNL,eAAA;EACA,iBAAA;EACA,gBAAA;AACJ;;AAEI;EACI,kBAAA;EACA,cAAA;EACA,YAAA;EACA,cAAA;EACA,MAAA;EACA,QAAA;EACA,oBAAA;AACR","sourcesContent":["/* Media parameters */\r\n$mobile-max: 768px\r\n$tablet-max: 1210px\r\n$desktop-max: 1440px\r\n\r\n// Отступы от краев экрана\r\n$mobile-offset: 8px\r\n$desktop-offset: 64px\r\n\r\n/* Colors */\r\n$base-white: #FFFFFF\r\n$base-black: #333333\r\n$base-shadow: #0500FF40\r\n$accent: #8000FF\r\n$accent-darken: #6000BF\r\n$link: #007AFF\r\n$error: #D60056\r\n// Также отвечает и за disabled\r\n$placeholder: #33333360\r\n\r\n/* Fonts */\r\n$fm-primary: 'Roboto Mono', 'Arial', monospace","@use \"../../../assets/styles/variables\" as *\r\n@use \"../../../assets/styles/mixins\" as mix\r\n\r\n.label\r\n    width: fit-content\r\n    position: relative\r\n    color: $accent\r\n    font-size: 20px\r\n    line-height: 120%\r\n    font-weight: 500\r\n\r\n.label-required\r\n    &::after\r\n        position: absolute\r\n        display: block\r\n        content: \"*\"\r\n        color: $error\r\n        top: 0\r\n        right: 0\r\n        translate: 100% -4px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `Label_label__uWjz7`,
	"label-required": `Label_label-required__u8Pg6`
};
export default ___CSS_LOADER_EXPORT___;
