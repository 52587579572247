import { Input } from "../input/Input";
import { Icon } from "../../base/icon/Icon";
import clsx from "clsx";
import styles from "./InputWithButton.module.sass";

export const InputWithButton = ({ onButtonClick, ...props }) => {
  return (
    <div className={clsx(styles["new-tag-input-wrapper"])}>
        <Input
            className={clsx(styles["input"])}
            {...props}
        />
        <button className={clsx(styles["new-tag-button"])} onClick={onButtonClick}>
            <Icon
                icon="fluent:add-circle-12-regular"
                rotate={0}
                size={16}
            />
        </button>
    </div>
  );
};