// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Media parameters */
/* Colors */
/* Fonts */
.Main_main__nobgC {
  max-width: 1440px;
  margin: 0 auto;
  width: calc(100vw - 128px);
  font-size: 20px;
  line-height: 120%;
  padding: 16px 0 32px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (max-width: 768px) {
  .Main_main__nobgC {
    width: calc(100vw - 16px);
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.sass","webpack://./src/pages/main/Main.module.sass","webpack://./src/assets/styles/mixins.sass"],"names":[],"mappings":"AAAA,qBAAA;AASA,WAAA;AAWA,UAAA;ACjBA;ECYI,iBFZU;EEaV,cAAA;EACA,0BAAA;EAdA,eAAA;EACA,iBAAA;EDEA,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAIJ;ACMI;EDhBJ;ICiBQ,yBAAA;EDHN;AACF","sourcesContent":["/* Media parameters */\r\n$mobile-max: 768px\r\n$tablet-max: 1210px\r\n$desktop-max: 1440px\r\n\r\n// Отступы от краев экрана\r\n$mobile-offset: 8px\r\n$desktop-offset: 64px\r\n\r\n/* Colors */\r\n$base-white: #FFFFFF\r\n$base-black: #333333\r\n$base-shadow: #0500FF40\r\n$accent: #8000FF\r\n$accent-darken: #6000BF\r\n$link: #007AFF\r\n$error: #D60056\r\n// Также отвечает и за disabled\r\n$placeholder: #33333360\r\n\r\n/* Fonts */\r\n$fm-primary: 'Roboto Mono', 'Arial', monospace","@use \"../../assets/styles/variables\" as *\r\n@use \"../../assets/styles/mixins\" as mix\r\n\r\n.main\r\n    @include mix.container\r\n    @include mix.text-1\r\n    padding: 16px 0 32px 0\r\n    display: flex\r\n    flex-direction: column\r\n    gap: 16px","@use \"./variables\" as *\r\n\r\n@mixin text-1\r\n    font-size: 20px\r\n    line-height: 120%\r\n\r\n@mixin text-2\r\n    font-size: 16px\r\n    line-height: 110%\r\n\r\n@mixin text-3\r\n    font-size: 12px\r\n    line-height: 110%\r\n\r\n@mixin container\r\n    max-width: $desktop-max\r\n    margin: 0 auto\r\n    width: calc(100vw - $desktop-offset * 2)\r\n\r\n    @media (max-width: $mobile-max)\r\n        width: calc(100vw - $mobile-offset * 2)\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Main_main__nobgC`
};
export default ___CSS_LOADER_EXPORT___;
