import { InputWithButton } from "../../form/inputWithButton/InputWithButton";
import { Input } from "../../form/input/Input";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { entries } from "mobx";
import clsx from "clsx";
import styles from "./InputPanel.module.sass";

export const InputPanel = observer(({ inputs, setInput, removeInput, updateInput, validateInput }) => {
  const [newInput, setNewInput] = useState("");

  const onButtonClick = () => {
    if (validateInput && validateInput(newInput)) return;

    setInput(newInput);
    setNewInput("");
  };

  return (
    <div>
      <div className={clsx(styles["input-list"])}>
        {
          entries(inputs).map(([key, value]) => (
            <Input
              key={key}
              value={value}
              onChange={(e) => updateInput(key, e.target.value)}
              remove
              removeFunc={() => removeInput(key)}
            />
          ))
        }
      </div>
      <InputWithButton
        type="text"
        value={newInput}
        onChange={(e) => setNewInput(e.target.value)}
        onButtonClick={onButtonClick}
        validate={validateInput}
      />
    </div>
  );
});