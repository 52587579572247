import { observer } from "mobx-react-lite";
import { DisplaySettings } from "../../pages/editor/displaySettings/DisplaySettings";
import { useState } from "react";
import { clsx } from "clsx";
import { Icon } from "../icon/Icon";
import { InputFile } from "../../form/inputFile/InputFile";
import { Button } from "../button/Button";
import styles from "./RightPanel.module.sass";

export const RightPanel = observer(() => {
  const [activeTab, setActiveTab] = useState("");

  const tabs = [
    {
      name: "Параметры вывода данных",
      component: <DisplaySettings />,
      icon: "material-symbols:display-settings-outline-rounded"
    }
  ];

  const changeActiveTab = (name) => {
    if (activeTab === name) {
      setActiveTab("");
      return;
    }

    setActiveTab(name);
  };

  return (
    <div className={clsx(styles["tab"], { [styles["tab-active"]]: activeTab !== "" })}>
        <div className={styles["tab-content"]}>
          {
            tabs.map((tab) =>
              <div key={tab.name} className={clsx(styles["tab-pane"], { [styles["tab-pane-active"]]: activeTab === tab.name })}>
                <div className={clsx(styles["tab-component"])}>
                  { tab.component }
                </div>
              </div>
            )
          }
        </div>
        <ul className={styles["tab-nav"]}>
          {
            tabs.map((tab) =>
              <li key={tab.name} className={styles["tab-nav-item"]}>
                <button type="button" className={clsx(styles["tab-button"], { [styles["tab-button-active"]]: activeTab === tab.name })} onClick={() => changeActiveTab(tab.name)}>
                    <Icon
                      icon={tab.icon}
                      rotate={0}
                      size={24}
                    />
                </button>
              </li>
            )
          }
        </ul>
    </div>
  );
});