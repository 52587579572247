import React from "react";

import { Constants } from "../constants/Constants";

//styles
import { clsx } from "clsx";
import styles from "./MainProjectSettings.module.sass";

//stores
import { useStores } from "../../../../stores/root-store-context";
import { observer } from "mobx-react-lite";

//blocks
import { TagPanel } from "../../../base/tagPanel/TagPanel";
import { InputPanel } from "../../../base/inputPanel/InputPanel";

//items
import { InputFile } from "../../../form/inputFile/InputFile";
import { Input } from "../../../form/input/Input";
import { Heading } from "../../../base/heading/Heading";

//functions
import { validateLimit } from "../../../../core/validate/validateLimits";

export const MainProjectSettings = observer(() => {
    const {
        projectStore: {
            mainSettingsStore: {
                title,
                setTitle,
                inputParameters,
                setInputParameter,
                deleteInputParameter,
                outputCriterias,
                setOutputCriteria,
                deleteOutputCriteria,
                limits,
                setNewLimit,
                deleteLimit,
                updateLimit,
                formulas,
                setNewFormula,
                deleteFormula,
                updateFormula,
                fileDLL,
                setFileDLL
            }
        }
    } = useStores();

    const onLoad = (event)=>{
        setFileDLL(event.target.files[0]);
    };

    const validateLimitInput = (value) => {
        return validateLimit([...inputParameters, ...outputCriterias], value);
    };

    const validateFormulas = (value) => {
        if (!value) return "Введите корректную формулу";

        return false;
    };

    return (
        <div className={clsx(styles["main-settings"])}>
            <Input
                placeholder="Название проекта"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
            <Constants />
            <div className={clsx(styles["main-setting-panel"])}>
                <Heading level={3}>
                    Параметры
                </Heading>
                <TagPanel
                    tags={inputParameters}
                    setTag={setInputParameter}
                    errorMessage="Этот параметр уже существует"
                    removeTag={deleteInputParameter}
                />
            </div>
            <div className={clsx(styles["main-setting-panel"])}>
                <Heading level={3}>
                    Критерии
                </Heading>
                <TagPanel
                    tags={outputCriterias}
                    setTag={setOutputCriteria}
                    removeTag={deleteOutputCriteria}
                    errorMessage="Этот критерий уже существует"
                />
            </div>
            <div className={clsx(styles["main-setting-panel"])}>
                <Heading level={3}>
                    Ограничения
                </Heading>
                <InputPanel
                    inputs={limits}
                    setInput={setNewLimit}
                    removeInput={deleteLimit}
                    updateInput={updateLimit}
                    validateInput={validateLimitInput}
                />
            </div>
            <div className={clsx(styles["main-setting-panel"])}>
                <Heading level={3}>
                    Формулы
                </Heading>
                <InputFile text={fileDLL ? fileDLL.name : "Загрузите файл"} onChange={onLoad} accept=".dll" />
                <InputPanel
                    inputs={formulas}
                    setInput={setNewFormula}
                    removeInput={deleteFormula}
                    updateInput={updateFormula}
                    validateInput={validateFormulas}
                />
            </div>
        </div>
    );
});