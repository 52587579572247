// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OptimizationSettings_row__GwH0w {
  display: flex;
  gap: 8px;
  align-items: center;
}

.OptimizationSettings_setting__z9VT1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/editor/optimizationSettings/OptimizationSettings.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;AAEF","sourcesContent":[".row\r\n  display: flex\r\n  gap: 8px\r\n  align-items: center\r\n\r\n.setting\r\n  display: flex\r\n  flex-direction: column\r\n  gap: 8px\r\n  margin-bottom: 16px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `OptimizationSettings_row__GwH0w`,
	"setting": `OptimizationSettings_setting__z9VT1`
};
export default ___CSS_LOADER_EXPORT___;
