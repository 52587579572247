import React, { useState } from "react";
import { InputWithButton } from "../../form/inputWithButton/InputWithButton";
import clsx from "clsx";
import styles from "./TagPanel.module.sass";
import { Tag } from "../tag/Tag";
import { keys } from "mobx";
import { observer } from "mobx-react-lite";

export const TagPanel = observer(({ tags, setTag, removeTag, errorMessage = false }) => {
  const [newTag, setNewTag] = useState("");

  const validateTag = (value) => {
    if (!value) {
      return "Введите корректное значение";
    }

    if (tags.has(value)) {
      if (!errorMessage) return true;

      return errorMessage;
    }

    return false;
  };

  const onButtonClick = () => {
    if (validateTag(newTag)) return;

    setTag(newTag);
    setNewTag("");
  };

  return (
    <div className={clsx(styles["tag-panel"])}>
      <div className={clsx(styles["tag-list"])}>
        {
          keys(tags).map((tag) => <Tag key={tag} name={tag} remove onRemove={removeTag} />)
        }
      </div>
      <InputWithButton
        type="text"
        value={newTag}
        onChange={(e) => setNewTag(e.target.value)}
        onButtonClick={onButtonClick}
        validate={validateTag}
      />
    </div>
  );
});