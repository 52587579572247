import { makeAutoObservable } from "mobx";

export class ModelStore {
  rootStore;

  points = [];
  isModelLoading = false;

  setPoints(points) {
    this.points = points;
  }

  setIsModelLoading(isLoading) {
    this.isModelLoading = isLoading;
  }

  // TODO: метод для получения данных о проекте с сервера
  async executeCalculate() {

  }

  // TODO: метод для построения данных запроса на сервер
  buildRequestData() {

  }

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
