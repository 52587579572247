// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Media parameters */
/* Colors */
/* Fonts */
.FeedbackForm_form__spvuq {
  position: relative;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 5px;
  box-shadow: 0 4px 20px rgba(5, 0, 255, 0.2509803922);
}

.FeedbackForm_item__tImaK {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.FeedbackForm_button__5iDrL {
  align-self: end;
}

.FeedbackForm_textarea__9H0BE {
  height: 120px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.sass","webpack://./src/components/pages/main/feedback-form/FeedbackForm.module.sass"],"names":[],"mappings":"AAAA,qBAAA;AASA,WAAA;AAWA,UAAA;ACjBA;EACI,kBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;EACA,oDAAA;AACJ;;AACA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ;;AAAA;EACI,aAAA;AAGJ","sourcesContent":["/* Media parameters */\r\n$mobile-max: 768px\r\n$tablet-max: 1210px\r\n$desktop-max: 1440px\r\n\r\n// Отступы от краев экрана\r\n$mobile-offset: 8px\r\n$desktop-offset: 64px\r\n\r\n/* Colors */\r\n$base-white: #FFFFFF\r\n$base-black: #333333\r\n$base-shadow: #0500FF40\r\n$accent: #8000FF\r\n$accent-darken: #6000BF\r\n$link: #007AFF\r\n$error: #D60056\r\n// Также отвечает и за disabled\r\n$placeholder: #33333360\r\n\r\n/* Fonts */\r\n$fm-primary: 'Roboto Mono', 'Arial', monospace","@use \"../../../../assets/styles/variables\" as *\r\n@use \"../../../../assets/styles/mixins\" as mix\r\n\r\n.form\r\n    position: relative\r\n    padding: 32px\r\n    display: flex\r\n    flex-direction: column\r\n    gap: 16px\r\n    border-radius: 5px\r\n    box-shadow: 0 4px 20px $base-shadow\r\n\r\n.item\r\n    display: flex\r\n    flex-direction: column\r\n    gap: 4px\r\n\r\n.button\r\n    align-self: end\r\n\r\n\r\n.textarea\r\n    height: 120px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `FeedbackForm_form__spvuq`,
	"item": `FeedbackForm_item__tImaK`,
	"button": `FeedbackForm_button__5iDrL`,
	"textarea": `FeedbackForm_textarea__9H0BE`
};
export default ___CSS_LOADER_EXPORT___;
