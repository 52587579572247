import styles from "./InputFile.module.sass";
import linkStyles from "../../../assets/styles/components/Link.module.sass";
import clsx from "clsx";

export const InputFile = ({ text, ...props }) => {
  return (
    <label className={clsx(styles["label"])}>
      <input type="file" className={clsx(styles["input"])} {...props} />
      <span className={clsx(linkStyles["link"])}>{ text }</span>
    </label>
  );
};