import { Heading } from "../../../base/heading/Heading";
import { entries } from "mobx";
import { InputWithButton } from "../../../form/inputWithButton/InputWithButton";
import { Input } from "../../../form/input/Input";
import { useState } from "react";
import { useStores } from "../../../../stores/root-store-context";
import { observer } from "mobx-react-lite";
import clsx from "clsx";
import styles from "./Constants.module.sass";

export const Constants = observer(() => {
    const {
    projectStore:
        {
            mainSettingsStore:
            {
                constants,
                setConstant,
                deleteConstant
            }
        }
    } = useStores();
    const [newConstant, setNewConstant] = useState("");

    const addNewConstant = () => {
        if (!newConstant) return;
        setConstant(newConstant, "");
        setNewConstant("");
    };

    const validateConstant = (value) => {
        if (!value.trim().length) {
            return "Название константы не может быть пустым";
        }
        
        return false;
    };

    return  (
    <div>
    <Heading level={3}>
        Константы
    </Heading>
    <div className={clsx(styles["inputs"])}>
    {
        entries(constants).map(([key, value]) => (
            <div className={clsx(styles["constant"])} key={key}>
                <div className={clsx(styles["constant-key"])}>
                    { key }
                </div>
                <Input
                    onChange={(e) => setConstant(key, e.target.value)}
                    value={value}
                    remove
                    removeFunc={(e) => deleteConstant(key)}
                    className={clsx(styles["constant-input"])}
                />
            </div>
        ))
    }
    </div>
    <InputWithButton
        placeholder="Введите название константы"
        type="text"
        onChange={(e) => setNewConstant(e.target.value)}
        value={newConstant}
        onButtonClick={addNewConstant}
        validate={validateConstant}
    />
</div>);
});