// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputWithButton_new-tag-button__Df9v2 {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px;
}

.InputWithButton_new-tag-input-wrapper__qEKeH {
  display: flex;
  align-items: center;
}

.InputWithButton_input__aw5nI {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/form/inputWithButton/InputWithButton.module.sass"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;AAEF;;AAAA;EACE,YAAA;AAGF","sourcesContent":[".new-tag-button\r\n  background: none\r\n  border: none\r\n  display: flex\r\n  align-items: center\r\n  cursor: pointer\r\n  padding: 4px\r\n\r\n.new-tag-input-wrapper\r\n  display: flex\r\n  align-items: center\r\n\r\n.input\r\n  flex-grow: 1"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"new-tag-button": `InputWithButton_new-tag-button__Df9v2`,
	"new-tag-input-wrapper": `InputWithButton_new-tag-input-wrapper__qEKeH`,
	"input": `InputWithButton_input__aw5nI`
};
export default ___CSS_LOADER_EXPORT___;
