import { React } from "react";
import { Hero } from "../../components/pages/main/hero/Hero";
import { Feedback } from "../../components/pages/main/feedback/Feedback";
import styles from "./Main.module.sass";


export const Main = () => {
  return (
    <main className={styles["main"]}>
      <Hero />
      {/* <DesktopInfo /> */}
      <Feedback />
    </main>
  );
};