/*
  Функция считает правильными такие паттерны:
  number<=variable<=number
  number<=variable
  variable<=number
 */
/**
 * Функция, валидирующая ограничение
 * @param {*} supportedVariables - Переменные, которые ранее указал пользователь (ограничения и критерии)
 * @param {*} limit - Ограничение
 */
export const validateLimit = (supportedVariables, limit) => {
  if (!limit) return ERROR_MESSAGE;

  const numberRegexp = /^\-?[0-9\.]+$/;
  const parts = limit.split("<=");

  if (parts.length > 3) return ERROR_MESSAGE;

  // number<=variable<=number
  if (parts.length === 3) {
    const [min, max] = [parts[0], parts[2]];

    if (numberRegexp.test(min) && numberRegexp.test(max) && supportedVariables.includes(parts[1]) && +min < +max) return false;

    return ERROR_MESSAGE;
  }

  // number<=variable либо variable<=number
  const [part1, part2] = parts;
  if (
    (numberRegexp.test(part1) && supportedVariables.includes(part2)) ||
    (supportedVariables.includes(part1) && numberRegexp.test(part2))
  ) return false;

  return ERROR_MESSAGE;
};

export const ERROR_MESSAGE = "Неккоректное ограничение";

// Названия переменных содержат только буквы и цифры, причем первая всегда буква
const variableRegexp = /^[a-zA-Z]\w*$/;