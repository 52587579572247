import { v4 as uuidv4 } from "uuid";
import { makeAutoObservable } from "mobx";

export class MainSettingsStore {
  rootStore;

  title = "";
  constants = new Map();
  inputParameters = new Set();
  outputCriterias = new Set();
  limits = new Map();
  formulas = new Map();
  fileDLL = undefined;


  setFileDLL(file){
    this.fileDLL = file;
  }

  setTitle(title) {
    this.title = title;
  }

  setConstant(key, value) {
    this.constants.set(key, value);
  }
  
  deleteConstant(key) {
    this.constants.delete(key);
  }

  setInputParameter(value) {
    this.inputParameters.add(value);
  }

  deleteInputParameter(value) {
    this.inputParameters.delete(value);
  }

  setOutputCriteria(value) {
    this.outputCriterias.add(value);
  }

  deleteOutputCriteria(value) {
    this.outputCriterias.delete(value);
  }

  setNewLimit(value) {
    const key = uuidv4();
    this.limits.set(key, value);
  }

  deleteLimit(key) {
    this.limits.delete(key);
  }

  updateLimit(key, value) {
    this.limits.set(key, value);
  }

  setNewFormula(value) {
    const key = uuidv4();
    this.formulas.set(key, value);
  }

  deleteFormula(key) {
    this.formulas.delete(key);
  }

  updateFormula(key, value) {
    this.formulas.set(key, value);
  }

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}