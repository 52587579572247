import { React } from "react";
import PropTypes from "prop-types";
import styles from "./Tag.module.sass";
import { Icon } from "../icon/Icon";
import clsx from "clsx";

export const Tag = ({ name = "", ...props }) => {

    const remove = (event) =>{
        console.warn("Был установлен флаг remove, но небыло указано действие onRemove", event);
    };

    return (
        <div className={[styles.tag].join(" ")} >
        <span>{name}</span>
        {
            props.remove &&
            <div className={ clsx(styles["remove"]) } onClick={props.onRemove ? () => props.onRemove(name) : (event) => remove(event)}>
                <Icon
                    icon="fluent:delete-12-regular"
                    rotate={0}
                    size={16}
                />
            </div>
        }
    </div>
    );
};

Tag.propTypes = { name: PropTypes.string };