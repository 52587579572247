import { Input } from "../../form/input/Input";
import clsx from "clsx";
import styles from "./Axis.module.sass";
import { useStores } from "../../../stores/root-store-context";
import { observer } from "mobx-react-lite";
import { Select, SelectOption } from "../../form/newSelect/Select";
import { keys } from "mobx";

export const Axis = observer(({ axisName, axis, setAxis }) => {
  const {
    projectStore:
    { mainSettingsStore: { inputParameters, outputCriterias } }
  } = useStores();

  return (
    <div className={clsx(styles["wrapper"])}>
      <div className={clsx(styles["axis-name"], styles["row"])}>
        <span>{axisName}:</span>
        <Select
        selectedOption={axis.name}
        setOption={(name) => setAxis({ ...axis, name })}
        placeholder="Выберите ось"
        className={clsx(styles["select"])}
        >
          {
            (keys(outputCriterias).concat(keys(inputParameters))).map((name) => (
              <SelectOption key={name} value={name}/>
            ))
          }
        </Select>
      </div>
      <div className={clsx(styles["row"])}>
        <span>min:</span>
        <Input
        type="number"
        value={axis.min}
        onChange={(e) => setAxis({ ...axis, min: e.target.value ? +e.target.value : null })} />
      </div>
      <div className={clsx(styles["row"])}>
        <span>max:</span>
        <Input
        type="number"
        value={axis.max}
        onChange={(e) => setAxis({ ...axis, max: e.target.value ? +e.target.value : null })}/>
      </div>
    </div>
  );
});