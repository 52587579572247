import clsx from "clsx";
import styles from "./Select.module.sass";
import { useState } from "react";
import { Children, cloneElement } from "react";
import { observer } from "mobx-react-lite";
import { Icon } from "../../base/icon/Icon";

// setOption передается только через родительский элемент Select
/**
 * Реакт-компонент для опций в селекте
 * @param value - Значение опции
 * @param text - Название опции в интерфейсе, необязательное
 */
export const SelectOption = ({ value, text, setOption }) => {
  return (
    <div className={clsx(styles["option"])} value={value} onClick={() => setOption(value)}>
      { text ?? value }
    </div>
  );
};

export const Select = observer(({ selectedOption, setOption, placeholder = "Выберите опцию", children, className }) => {
  const [active, setActive] = useState(false);
  const setOptionFromSelect = (value) => {
    setOption(value);
    setActive(false);
  };

  return (
  <div className={clsx(styles["select"], className)}>
    <div className={clsx(styles["selected-option"])} onClick={() => setActive(!active)}>
      <span className={clsx(styles["selected-option-text"])}>
        { selectedOption ? selectedOption : placeholder }
      </span>
      <Icon icon="material-symbols:arrow-drop-up-rounded" rotate={active ? 0 : 180}/>
    </div>
    {
      active &&
      <div className={clsx(styles["options"])}>
        {
          Children.map(children, (child) => (
            cloneElement(child, { setOption: setOptionFromSelect })
          ))
        }
      </div>
    }
  </div>
  );
});