import { makeAutoObservable } from "mobx";

export const DISPLAY_TYPES = ["line", "points", "mesh", "mesh-full"];

export class DisplaySettingsStore {
  rootStore;

  displayType = "points";
  displayParameter = null;

  setDisplayType(type) {
    this.displayType = type;
  }

  setDisplayParameter(value) {
    this.displayParameter = value;
  }

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
