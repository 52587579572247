import { Heading } from "../../../base/heading/Heading";
import { useStores } from "../../../../stores/root-store-context";
import { observer } from "mobx-react-lite";
import { Axis } from "../../../base/axis/Axis";
import styles from "./CalculationSettings.module.sass";
import clsx from "clsx";
import { Input } from "../../../form/input/Input";

export const CalculationSettings = observer(() => {
  const {
    projectStore:
    {
      calculationSettingsStore:
      {
        xAxis,
        setXAxis,
        yAxis,
        setYAxis,
        pointsCount,
        setPointsCount,
        stepsCount,
        setStepsCount,
        precision,
        setPrecision
      }
    }
  } = useStores();

  return (
    <div className={clsx(styles["wrapper"])}>
      <Heading level={2}>
        Параметры расчетов
      </Heading>
      <div className={clsx(styles["content"])}>
        <div className={clsx(styles["axis"])}>
          <Axis axisName="X" axis={xAxis} setAxis={setXAxis} />
          <Axis axisName="Y" axis={yAxis} setAxis={setYAxis} />
        </div>
        <div className={clsx(styles["row"])}>
          <span>
            Кол-во точек:
          </span>
          <Input
            value={pointsCount}
            onChange={(e) => setPointsCount(+e.target.value)}
            type="number"
          />
        </div>
        <div className={clsx(styles["row"])}>
          <span>
            Макс. число шагов:
          </span>
          <Input
          value={stepsCount}
          onChange={(e) => setStepsCount(+e.target.value)}
          type="number"
          />
        </div>
        <div className={clsx(styles["row"])}>
          <span>
            Точность:
          </span>
          <Input
          value={precision}
          onChange={(e) => setPrecision(+e.target.value)}
          type="number"
          />
        </div>
      </div>
    </div>
  );
});