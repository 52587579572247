import { React } from "react";
import { Heading } from "../../components/base/heading/Heading";

export const Error = () => {
  return (
    <main>
      <Heading>
        404 page
      </Heading>
    </main>
  );
};