// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DisplaySettings_wrapper__N9K6X {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/editor/displaySettings/DisplaySettings.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;AACF","sourcesContent":[".wrapper\r\n  display: flex\r\n  flex-direction: column\r\n  gap: 16px\r\n  flex-grow: 1"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `DisplaySettings_wrapper__N9K6X`
};
export default ___CSS_LOADER_EXPORT___;
