import { React, Suspense, useState, useEffect, useRef, useCallback } from "react";
import PropTypes, { element } from "prop-types";
import { Canvas, useFrame, useThree, useLoader } from "@react-three/fiber";
import { OrbitControls, TrackballControls } from "@react-three/drei";
import { Vector3 } from "three";
import { Group } from "./Group";
// import { triangulation } from "../../core/triangulation";

const CameraController = (props) => {
    const camera = useThree((state) => state.camera);

    useEffect(() => {
        console.log("Control style changed to: ", props.controlStyle);
        if (props.controlStyle === "orbit") {
            camera.up = new Vector3(0, 0, 1);
            camera.lookAt(0, 0, 0);
        }
    }, [props.controlStyle, camera]);

    if (props.controlStyle === "orbit") {
        return <OrbitControls rotateSpeed={2} />;
    }
    return <TrackballControls rotateSpeed={5} />;
};

export const CanvasArea = ({ groups, type = "line", cameraControl = "orbit", cameraType = "perspective", axis = false, color = null, ...props }) => {
    const [_groups, setGroups] = useState([]);

    const canvasRef = useRef(null);

    console.log("rerender canvas area");

    useEffect(() => {

        setGroups(groups.map((elem)=>{
                return <Group
                    type={elem.type}
                    points={elem.points}
                    colors={
                        {
                            points: elem.colorPoints ? elem.colorPoints : color ? color : "#9c6666",
                            lines: elem.colorLines ? elem.colorLines : color ? color : "#9c88ff",
                            mesh: elem.colorMesh ? elem.colorMesh : color ? color : `#22ff44`
                        }
                    }
                    hideLines={elem.hideLines ? elem.hideLines : false}
                />;
            }
        ));
    }, [groups, type]);

    return (
        <Canvas ref={canvasRef} camera={{ position: [0, 1, 1], far: 10, near: 0.1, zoom: cameraType === "orthographic" ? 400 : 1 }} orthographic={cameraType === "orthographic"} >
            <CameraController controlStyle={cameraControl} />
            {axis && <axesHelper args={[1]} />}
            {
                _groups?.length && _groups
            }
        </Canvas>
    );
};

CanvasArea.propTypes = {
    type: PropTypes.oneOf(["line", "points", "mesh", "mesh-full", "lineLevel"]),
    cameraControl: PropTypes.oneOf(["orbit", "trackball"]),
    cameraType: PropTypes.oneOf(["orthographic", "perspective"]),
    axis: PropTypes.bool,
    points: PropTypes.arrayOf(
        PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
            z: PropTypes.number
        })
    ),
    color: PropTypes.string
};
