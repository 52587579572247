// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Media parameters */
/* Colors */
/* Fonts */
.Feedback_feedback__pKNx9 {
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Feedback_content__LU78w {
  margin: 16px 0 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}
@media (max-width: 1210px) {
  .Feedback_content__LU78w {
    grid-template-columns: 2fr 1fr;
  }
}
@media (max-width: 768px) {
  .Feedback_content__LU78w {
    grid-template-columns: 1fr;
  }
}

.Feedback_text__Bw\\+eb {
  max-width: 850px;
  font-size: inherit;
  margin: 0;
}

.Feedback_contacts__dv2ID {
  font-size: inherit;
  font-style: normal;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.Feedback_contact__ppYfw {
  display: flex;
  flex-direction: column;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.sass","webpack://./src/components/pages/main/feedback/Feedback.module.sass"],"names":[],"mappings":"AAAA,qBAAA;AASA,WAAA;AAWA,UAAA;AChBA;EACI,eAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;;AAEA;EACI,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,SAAA;AACJ;AAEI;EAPJ;IAQQ,8BAAA;EACN;AACF;AAAI;EAVJ;IAWQ,0BAAA;EAGN;AACF;;AAFA;EACI,gBAAA;EACA,kBAAA;EACA,SAAA;AAKJ;;AAHA;EACI,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAMJ;;AAJA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AAOJ","sourcesContent":["/* Media parameters */\r\n$mobile-max: 768px\r\n$tablet-max: 1210px\r\n$desktop-max: 1440px\r\n\r\n// Отступы от краев экрана\r\n$mobile-offset: 8px\r\n$desktop-offset: 64px\r\n\r\n/* Colors */\r\n$base-white: #FFFFFF\r\n$base-black: #333333\r\n$base-shadow: #0500FF40\r\n$accent: #8000FF\r\n$accent-darken: #6000BF\r\n$link: #007AFF\r\n$error: #D60056\r\n// Также отвечает и за disabled\r\n$placeholder: #33333360\r\n\r\n/* Fonts */\r\n$fm-primary: 'Roboto Mono', 'Arial', monospace","@use \"../../../../assets/styles/variables\" as *\r\n@use \"../../../../assets/styles/mixins\" as mix\r\n\r\n\r\n.feedback\r\n    padding: 32px 0\r\n    display: flex\r\n    flex-direction: column\r\n    gap: 16px\r\n\r\n.content\r\n    margin: 16px 0 0 0\r\n    display: grid\r\n    grid-template-columns: 1fr 1fr\r\n    gap: 32px\r\n\r\n\r\n    @media (max-width: $tablet-max)\r\n        grid-template-columns: 2fr 1fr\r\n\r\n    @media (max-width: $mobile-max)\r\n        grid-template-columns: 1fr\r\n\r\n.text\r\n    max-width: 850px\r\n    font-size: inherit\r\n    margin: 0\r\n\r\n.contacts\r\n    font-size: inherit\r\n    font-style: normal\r\n    display: flex\r\n    flex-direction: column\r\n    gap: 32px\r\n\r\n.contact\r\n    display: flex\r\n    flex-direction: column\r\n    gap: 8px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feedback": `Feedback_feedback__pKNx9`,
	"content": `Feedback_content__LU78w`,
	"text": `Feedback_text__Bw+eb`,
	"contacts": `Feedback_contacts__dv2ID`,
	"contact": `Feedback_contact__ppYfw`
};
export default ___CSS_LOADER_EXPORT___;
