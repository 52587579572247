import { makeAutoObservable } from "mobx";

export const START_OPTIONS = ["0,0,0,...,0", "1,1,1,...,1", "1,-1,1,...", "-1,1,-1,...", "center_of_region", "previous_optimum", "random"];
export const VALUE_TYPE_OPTIONS = ["first_valid", "optimum"];

export class OptimizationSettingsStore {
  rootStore;

  regionCenter = {
    startPoint: "random",
    value: {
      type: "first_valid",
      n_point: 1,
      n_iteration: 1000
    }
  };

  regionSize = { startPoint: "center_of_region" };

  firstPointOnGrid = {
    startPoint: "random",
    value: {
      type: "first_valid",
      n_point: 1,
      n_iteration: 1000
    }
  };

  internalGridNotes = { startPoint: "previous_optimum" };

  setRegionCenter(regionCenter) {
    this.regionCenter = regionCenter;
  }

  setRegionSize(regionSize) {
    this.regionSize = regionSize;
  }

  setFirstPointOnGrid(firstPointOnGrid) {
    this.firstPointOnGrid = firstPointOnGrid;
  }

  setInternalGridNotes(internalGridNotes) {
    this.internalGridNotes = internalGridNotes;
  }

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}