// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Articles_articles__Gb2Lf {
  display: flex;
  flex-direction: column;
  padding: 47px 64px 32px 64px;
}

.Articles_heading__6CWCM {
  margin-bottom: 32px;
}

.Articles_articleList__y6\\+Mp {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.Articles_pagination-container__OSwXC {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}`, "",{"version":3,"sources":["webpack://./src/pages/articles/Articles.module.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,4BAAA;AACJ;;AACA;EACI,mBAAA;AAEJ;;AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AAGJ;;AADA;EACI,aAAA;EACA,uBAAA;EACA,gBAAA;AAIJ","sourcesContent":[".articles\r\n    display: flex\r\n    flex-direction: column\r\n    padding: 47px 64px 32px 64px\r\n\r\n.heading\r\n    margin-bottom: 32px\r\n\r\n.articleList\r\n    display: flex\r\n    flex-direction: column\r\n    gap: 24px\r\n\r\n.pagination-container\r\n    display: flex\r\n    justify-content: center\r\n    margin-top: 32px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"articles": `Articles_articles__Gb2Lf`,
	"heading": `Articles_heading__6CWCM`,
	"articleList": `Articles_articleList__y6+Mp`,
	"pagination-container": `Articles_pagination-container__OSwXC`
};
export default ___CSS_LOADER_EXPORT___;
