import { React, Suspense, useState, useEffect, useRef, useCallback } from "react";
import PropTypes, { element } from "prop-types";
import { Vector3, BufferGeometry, TextureLoader, WireframeGeometry, CameraHelper } from "three";
import { ConvexGeometry } from "three/examples/jsm/geometries/ConvexGeometry";
import circle from "../../../assets/images/geometry/circle.png";
import { Canvas, useFrame, useThree, useLoader } from "@react-three/fiber";

import { fetchData, fetchDataLocal } from "../../../core/api";

function getRandomColor(alpha = 1) {
    const randomColor = (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, "0");
    const _alpha = (Math.round(alpha * 255)).toString(16);
    return "#" + randomColor;
}

const Normalization = (points) => {
    if (points.length <= 0)
        return points;
    const maxX = Math.max(...points.map(e => { return e.x; }));
    const maxY = Math.max(...points.map(e => { return e.y; }));
    const maxZ = Math.max(...points.map(e => { return e.z; }));

    const minX = Math.min(...points.map(e => { return e.x; }));
    const minY = Math.min(...points.map(e => { return e.y; }));
    const minZ = Math.min(...points.map(e => { return e.z; }));

    for (let i = 0; i < points.length; i++) {
        points[i].x = (points[i].x - minX) / (maxX - minX);
        points[i].y = (points[i].y - minY) / (maxY - minY);
        points[i].z = (points[i].z - minZ) / (maxZ - minZ);
    }

    return points;
};

function getGeometryBasic(points) {
    const geometryBuffer = new BufferGeometry()
        .setFromPoints(
            [...points]
                .map((element) => { return new Vector3(element.x, element.y, element.z); })
        );
    return geometryBuffer;
}

function getGeometryTriangle(triangle) {
    const triangleBuffer = new BufferGeometry().setFromPoints(triangle);
    return triangleBuffer;
}

function getGeometryLineLevel(lines) {

    const triangleBuffer = new BufferGeometry().setFromPoints(lines);
    return triangleBuffer;
}

function getGeometryWireframe(geometryBuffer) {
    const geometryWireframe = new WireframeGeometry(geometryBuffer);
    return geometryWireframe;
}

function getGeometryConvex(geometryBuffer) {
    const vertices = [];
    for (let i = 0; i < geometryBuffer.getAttribute("position").count; i++)
        vertices.push(new Vector3().fromBufferAttribute(geometryBuffer.getAttribute("position"), i));
    const geometryConvex = new ConvexGeometry(vertices);
    return geometryConvex;
}



export const Group = ({ type, points, bias = { x: -0.5, y: -0.5, z: -0.5 }, colors = { points: "#9c6666", lines: "#9c88ff", mesh: `#22ff44` }, hideLines = false }) => {

    const [normalization, setNormalization] = useState([]);
    useEffect(() => {
        setNormalization(Normalization(points));
    }, [points]);

    const [geometryBuffer, setGeometryBuffer] = useState();
    const [geometryWireframe, setGeometryWireframe] = useState();
    useEffect(() => {
        setGeometryBuffer(getGeometryBasic(normalization.map((element) => { return new Vector3(element.x, element.y, element.z); })));
    }, [normalization]);

    const [geometryConvex, setGeometryConvex] = useState();
    useEffect(() => {
        if (geometryBuffer) {
            setGeometryConvex(getGeometryConvex(geometryBuffer));
        }
    }, [geometryBuffer]);

    const [triangleBuffer, setTriangleBuffer] = useState();

    useEffect(() => {

        const callBackendAPI = async () => {

            try {
                const response = await fetchData("/triangle", {
                    mode: "no-cors",
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ points: normalization })
                },
                true
            );
                return response;
            } catch (e) {
                console.log("Не удалось обновить данные", e);
            }
        };

        callBackendAPI().then(result => {
            if (result) {
                setTriangleBuffer(getGeometryTriangle(result.triangle));
                setGeometryWireframe(getGeometryWireframe(getGeometryTriangle(result.triangle)));
            }
        });
    }, [normalization]);

    const [geometryLevel, setGeometryLevel] = useState([]);
    useEffect(() => {
        const callBackendAPILineLevel = async (level) => {


            try {
                const response = await fetchData("/lineLevel", {
                    mode: "no-cors",
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ points: normalization, level: level })
                },
                true
            );
                return response;
            } catch (e) {
                console.log("Не удалось обновить данные", e);
            }
        };

        callBackendAPILineLevel().then(result => {
            if (result?.levelLine?.length > 0) {
                let geometries = [];
                result.levelLine.forEach(circuit => { geometries.push(getGeometryBasic(circuit)); });
                setGeometryLevel([...geometries]);
            }
        });
    }, [normalization]);

    const CircleImg = useLoader(TextureLoader, circle);
    const pointsMaterial = <pointsMaterial map={CircleImg} size={0.03} color={colors.points} transparent />;
    const lineMaterial = <lineBasicMaterial attach="material" color={colors.lines} linewidth={10} linecap={"round"} linejoin={"round"} />;
    const lineMaterialRED = <lineBasicMaterial attach="material" color="#FF0000" linewidth={10} linecap={"round"} linejoin={"round"} />;
    const meshMaterial = <meshBasicMaterial transparent={true} opacity={0.2} color={colors.mesh} />;




    switch (type) {
        case "line":
            type = <line geometry={geometryBuffer}>
                {lineMaterial}
            </line>;
            break;
        case "points":
            type = <points geometry={geometryBuffer}>
                {pointsMaterial}
            </points>;
            break;
        case "mesh":
            type = <><mesh geometry={triangleBuffer}>
                {meshMaterial}
            </mesh>
                {!hideLines && <lineSegments geometry={geometryWireframe}>
                    <lineBasicMaterial color={colors.lines} />
                </lineSegments>}
            </>;
            break;
        case "lineLevel":
            type = <>
                {
                    geometryLevel.map((elem) => {
                        return <line geometry={elem}>
                            {<lineBasicMaterial attach="material" color={getRandomColor()} linewidth={10} linecap={"round"} linejoin={"round"} />}
                        </line>;
                    }
                    )
                }
            </>
                ;
            break;
        case "mesh-full":
            type = <><mesh geometry={geometryConvex}>
                {meshMaterial}
            </mesh>
                {
                    !hideLines &&
                    <lineSegments geometry={geometryConvex}>
                        <lineBasicMaterial color={colors.lines} />
                    </lineSegments>
                }
            </>;
            break;
        default:
            type = null;
    }

    return <group position={[bias.x, bias.y, bias.z]}>
        {type}
    </group>;
};

Group.propTypes = {
    type: PropTypes.oneOf(["line", "points", "mesh", "mesh-full", "lineLevel"]),
    points: PropTypes.arrayOf(
        PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
            z: PropTypes.number
        })
    )
};