import clsx from "clsx";
import { React } from "react";
import { Logo } from "../base/logo/Logo";
import { NavLink } from "react-router-dom";
import { Icon } from "../base/icon/Icon";
import { Heading } from "../base/heading/Heading";
import styles from "./Header.module.sass";

export const Header = () => {
  const isMobile = () => {
    return window.innerWidth <= 768;
  };

  return (
    <header className={styles["header"]}>
      <div className={styles["inner"]}>
        <NavLink className={styles["logo"]} to="/">
          <Logo size={64} adaptiveSize={32} alt="Impro - система визуальных вычислений."/>
          <Heading>
            Impro
          </Heading>
        </NavLink>
        <nav className={styles["menu"]}>
          <ul className={styles["menu-list"]}>
            <li className={styles["menu-item"]}>
              <NavLink to="" className={({ isActive }) => clsx(styles["link"], { [styles["link--active"]]: isActive })}>
                  Главная
              </NavLink>
            </li>
            <li className={styles["menu-item"]}>
              <NavLink to="editor" className={({ isActive }) => clsx(styles["link"], { [styles["link--active"]]: isActive })}>
                Редактор
              </NavLink>
            </li>
            <li className={styles["menu-item"]}>
              <NavLink to="articles" className={({ isActive }) => clsx(styles["link"], { [styles["link--active"]]: isActive })}>
                Информация
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className={styles["user-container"]}>
          <NavLink to="auth" className={styles["link"]}>
            <Icon icon="material-symbols:login-rounded" size={{ "x": 24, "y": 24 }}/>
            { !isMobile() &&
              "Войти"
            }
          </NavLink>
        </div>
      </div>
    </header>
  );
};