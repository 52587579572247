export const getInputLimit = (input, min, max) => {
  let inputLimit = "";
  if (min) {
    inputLimit += `${min}<=${input}`;
  }
  if (max) {
    inputLimit = inputLimit.length ? `${inputLimit}<=${max}` : `${input}<=${max}`;
  }

  return inputLimit;
};