// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputFile_input__TLiAL {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  padding: 0;
  border: 0;
}

.InputFile_label__V\\+sxN {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/form/inputFile/InputFile.module.sass"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,QAAA;EACA,SAAA;EACA,kBAAA;EACA,gBAAA;EACA,UAAA;EACA,SAAA;AACF;;AACA;EACE,aAAA;AAEF","sourcesContent":[".input\r\n  opacity: 0\r\n  width: 0\r\n  height: 0\r\n  position: absolute\r\n  overflow: hidden\r\n  padding: 0\r\n  border: 0\r\n\r\n.label\r\n  display: flex"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `InputFile_input__TLiAL`,
	"label": `InputFile_label__V+sxN`
};
export default ___CSS_LOADER_EXPORT___;
