import { makeAutoObservable } from "mobx";

export class CalculationSettingsStore {
  rootStore;

  xAxis = { name: null, min: null, max: null };
  yAxis = { name: null, min: null, max: null };
  pointsCount = 0;
  stepsCount = 0;
  precision = 0;

  setXAxis(value) {
    this.xAxis = value;
  }

  clearXAxis() {
    this.xAxis = { name: null, min: null, max: null };
  }

  setYAxis(value) {
    this.yAxis = value;
  }

  clearYAxis() {
    this.yAxis = { name: null, min: null, max: null };
  }

  setPointsCount(value) {
    this.pointsCount = value;
  }

  setStepsCount(value) {
    this.stepsCount = value;
  }

  setPrecision(value) {
    this.precision = value;
  }

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}