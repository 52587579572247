// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Media parameters */
/* Colors */
/* Fonts */
.Tag_tag__7nTCL {
  font-size: 16px;
  line-height: 110%;
  font-family: "Roboto Mono", "Arial", monospace;
  padding: 4px;
  border: 1px solid #8000FF;
  border-radius: 5px;
  align-items: baseline;
  cursor: pointer;
  position: relative;
}

.Tag_remove__zcQUl {
  position: absolute;
  right: -8px;
  top: -8px;
  background: #FFFFFF;
  border-radius: 8px;
  width: 16px;
  height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.sass","webpack://./src/components/base/tag/Tag.module.sass","webpack://./src/assets/styles/mixins.sass"],"names":[],"mappings":"AAAA,qBAAA;AASA,WAAA;AAWA,UAAA;ACjBA;ECII,eAAA;EACA,iBAAA;EDHA,8CDgBS;ECfT,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,qBAAA;EACA,eAAA;EACA,kBAAA;AAEJ;;AAAA;EACI,kBAAA;EACA,WAAA;EACA,SAAA;EACA,mBDPS;ECQT,kBAAA;EACA,WAAA;EACA,YAAA;AAGJ","sourcesContent":["/* Media parameters */\r\n$mobile-max: 768px\r\n$tablet-max: 1210px\r\n$desktop-max: 1440px\r\n\r\n// Отступы от краев экрана\r\n$mobile-offset: 8px\r\n$desktop-offset: 64px\r\n\r\n/* Colors */\r\n$base-white: #FFFFFF\r\n$base-black: #333333\r\n$base-shadow: #0500FF40\r\n$accent: #8000FF\r\n$accent-darken: #6000BF\r\n$link: #007AFF\r\n$error: #D60056\r\n// Также отвечает и за disabled\r\n$placeholder: #33333360\r\n\r\n/* Fonts */\r\n$fm-primary: 'Roboto Mono', 'Arial', monospace","@use \"../../../assets/styles/variables\" as *\r\n@use \"../../../assets/styles/mixins\" as mix\r\n\r\n.tag\r\n    @include mix.text-2\r\n    font-family: $fm-primary\r\n    padding: 4px\r\n    border: 1px solid $accent\r\n    border-radius: 5px\r\n    align-items: baseline\r\n    cursor: pointer\r\n    position: relative\r\n\r\n.remove\r\n    position: absolute\r\n    right: -8px\r\n    top: -8px\r\n    background: $base-white //Must use color theme\r\n    border-radius: 8px\r\n    width: 16px\r\n    height: 16px","@use \"./variables\" as *\r\n\r\n@mixin text-1\r\n    font-size: 20px\r\n    line-height: 120%\r\n\r\n@mixin text-2\r\n    font-size: 16px\r\n    line-height: 110%\r\n\r\n@mixin text-3\r\n    font-size: 12px\r\n    line-height: 110%\r\n\r\n@mixin container\r\n    max-width: $desktop-max\r\n    margin: 0 auto\r\n    width: calc(100vw - $desktop-offset * 2)\r\n\r\n    @media (max-width: $mobile-max)\r\n        width: calc(100vw - $mobile-offset * 2)\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `Tag_tag__7nTCL`,
	"remove": `Tag_remove__zcQUl`
};
export default ___CSS_LOADER_EXPORT___;
