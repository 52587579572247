import { ProjectStore } from "./project-store";

class RootStore {
  projectStore;

  constructor() {
    this.projectStore = new ProjectStore(this);
  }
}

export const rootStore = new RootStore();