import { useState } from "react";

//styles
import { clsx } from "clsx";
import styles from "./LeftPanel.module.sass";

//stores
import { observer } from "mobx-react-lite";
import { useStores } from "../../../stores/root-store-context";
import { rootStore } from "../../../stores/root-store";

//blocks
import { CalculationSettings } from "../../pages/editor/calculationSettings/CalculationSettings";
import { MainProjectSettings } from "../../pages/editor/mainProjectSettings/MainProjectSettings";
import { OptimizationSettings } from "../../pages/editor/optimizationSettings/OptimizationSettings";

//items
import { Icon } from "../icon/Icon";
import { InputFile } from "../../form/inputFile/InputFile";
import { Button } from "../button/Button";

//functions
import { parseLimits } from "../../../core/editor/parseLimits";
import { fetchData } from "../../../core/api";

const MAX_COUNT_REQUEST_UPDATE_CALCULATION_DATA = 40;
const MAX_TIMEOUT_REQUEST_UPDATE_CALCULATION_DATA = 1000 * 40;
const TIMEOUT_REQUEST_UPDATE_CALCULATION_DATA = 1000;

const isDataCorrect = (points) => {
  return points !== "Идет вычисление";
};

export const LeftPanel = observer(() => {
  const [activeTab, setActiveTab] = useState("");
  const { projectStore: { uploadModel } } = useStores();
  const { projectStore: { loadData } } = useStores();

  const tabs = [
    {
      name: "Проект",
      component: <MainProjectSettings />,
      icon: "fluent:math-formula-16-filled"
    },
    {
      name: "Параметры расчета",
      component: <CalculationSettings />,
      icon: "fluent:braces-variable-20-filled"
    },
    {
      name: "Оптимизация",
      component: <OptimizationSettings />,
      icon: "material-symbols:tv-options-input-settings-outline-rounded"
    },
    // {
    //   name: "Параметры вывода данных",
    //   component: <DisplaySettings />,
    //   icon: "material-symbols:display-settings-outline-rounded"
    // }
  ];

  const changeActiveTab = (name) => {
    if (activeTab === name) {
      setActiveTab("");
      return;
    }

    setActiveTab(name);
  };

  const uploadFile = (event) => {
    const [file] = event.target.files;
    const fileReader = new FileReader();

    fileReader.addEventListener("load", () => {
      const modelJson = JSON.parse(fileReader.result);
      uploadModel(modelJson);
    });

    fileReader.readAsText(file);
  };

  const onSend = (event) => {

    let _variables = [];
    _variables.push( { ...rootStore.projectStore.calculationSettingsStore.xAxis, "grid": rootStore.projectStore.calculationSettingsStore.stepsCount } );
    _variables.push( { ...rootStore.projectStore.calculationSettingsStore.yAxis, "grid": rootStore.projectStore.calculationSettingsStore.stepsCount } );

    //TODO: должно быть изменяемым
    let optimization = {
      "region_center": {
        "start_point": "random",
        "value": {
          "type": "first_valid",
          "n_point": 1,
          "n_iteration": 1000
        }
      },
      "region_size": { "start_point": "center_of_region" },
      "first_point_on_grid": {
        "start_point": "random",
        "value": {
          "type": "first_valid",
          "n_point": 1,
          "n_iteration": 1000
        }
      },
      "internal_grid_notes": { "start_point": "previous_optimum" }
    };

    let limits = [];
    rootStore.projectStore.mainSettingsStore.limits.forEach(elem => { if(elem) limits.push(elem); } );
    let input = [];
    rootStore.projectStore.mainSettingsStore.inputParameters.forEach(elem => { if(elem) input.push(elem); } );
    let output = [];
    rootStore.projectStore.mainSettingsStore.outputCriterias.forEach(elem => { if(elem) output.push(elem); } );
    limits = parseLimits(limits, input, output);

    let calculate_params = {
      variables: _variables,
      max_step_numbers: Number(rootStore.projectStore.calculationSettingsStore.stepsCount),
      min_change: Number(rootStore.projectStore.calculationSettingsStore.precision),
      optimization: optimization
    };
    let project = {
      title: rootStore.projectStore.mainSettingsStore.title,
      const: rootStore.projectStore.mainSettingsStore.constants,
      input: limits.input,
      output: limits.output,
      //formulas: formulas,
      calculate_params: calculate_params
    };
    if (rootStore.projectStore.mainSettingsStore.fileDLL)
        project.file = rootStore.projectStore.mainSettingsStore.fileDLL.name;

    let form = new FormData();
    if (rootStore.projectStore.mainSettingsStore.fileDLL)
      form.append("dll_file", rootStore.projectStore.mainSettingsStore.fileDLL);
    form.append("task", JSON.stringify(project));

    fetchData("/calculate/calculate.php", {
      method: "POST",
      body: form,
      //mode: "no-cors"
    }).then((result) => {

      const updateData = async (timeout, countRequestsLeft, timeoutLeft) => {
        let resultGet = undefined;

        fetchData("/calculate/calculate.php?name_file=" + result?.name_file, {
          method: "GET"
          //mode: "no-cors"
        }).then((result) => {
          if( isDataCorrect(result) ){
            console.log(result);
            loadData(result);
            resultGet = result;
          }
          if(timeoutLeft > 0 && countRequestsLeft > 0 && resultGet === undefined){
            setTimeout(updateData, timeout, timeout, countRequestsLeft-1, timeoutLeft-timeout);
          }
        });
      };

      if(result?.name_file){
        setTimeout(updateData,
          TIMEOUT_REQUEST_UPDATE_CALCULATION_DATA,
          TIMEOUT_REQUEST_UPDATE_CALCULATION_DATA,
          MAX_COUNT_REQUEST_UPDATE_CALCULATION_DATA,
          MAX_TIMEOUT_REQUEST_UPDATE_CALCULATION_DATA
        );
      }
    });
  };

  return (
    <div className={clsx(styles["tab"], { [styles["tab-active"]]: activeTab !== "" })}>
      <div className={styles["tab-content"]}>
        {
          tabs.map((tab) =>
            <div key={tab.name} className={clsx(styles["tab-pane"], { [styles["tab-pane-active"]]: activeTab === tab.name })}>
              <div className={clsx(styles["tab-component"])}>
                {tab.component}
              </div>
              <div className={clsx(styles["tab-project-buttons"])}>
                <InputFile text="Загрузить проект" onChange={uploadFile} accept="application/JSON" />
                <Button onClick={onSend}>Построить</Button>
              </div>
            </div>
          )
        }
      </div>
      <ul className={styles["tab-nav"]}>
        {
          tabs.map((tab) =>
            <li key={tab.name} className={styles["tab-nav-item"]}>
              <button type="button" className={clsx(styles["tab-button"], { [styles["tab-button-active"]]: activeTab === tab.name })} onClick={() => changeActiveTab(tab.name)}>
                <Icon
                  icon={tab.icon}
                  rotate={0}
                  size={24}
                />
              </button>
            </li>
          )
        }
      </ul>
    </div>
  );
});