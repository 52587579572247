import { makeAutoObservable, reaction } from "mobx";
import { MainSettingsStore } from "./main-settings-store";
import { CalculationSettingsStore } from "./calculation-settings-store";
import { OptimizationSettingsStore } from "./optimization-settings-store";
import { ModelStore } from "./model-store";
import { DisplaySettingsStore } from "./display-settings-store";
import { getInputLimit } from "../core/helpers/getInputLimit";

/* Стор, в котором лежат сторы, связанные с открытым проектом в редакторе */
export class ProjectStore {
  rootStore;
  mainSettingsStore;
  calculationSettingsStore;
  optimizationSettingsStore;
  displaySettingsStore;
  modelStore;

  uploadModel(model) {
    if (model.title) {
      this.mainSettingsStore.title = model.title;
    }

    if (model.const && model.const.length) {
      model.const.forEach((constant) => {
        this.mainSettingsStore.setConstant(constant.name, constant.value);
      });
    }

    if (model.input && model.input.length) {
      model.input.forEach((input) => {
        const { name, min, max } = input;
        this.mainSettingsStore.setInputParameter(name);

        const inputLimit = getInputLimit(name, min, max);

        inputLimit.length && this.mainSettingsStore.setNewLimit(inputLimit);
      });
    }

    if (model.output && model.output.length) {
      model.output.forEach((output) => {
        const { name, min, max } = output;
        this.mainSettingsStore.setOutputCriteria(name);

        const inputLimit = getInputLimit(name, min, max);

        inputLimit.length && this.mainSettingsStore.setNewLimit(inputLimit);
      });
    }

    if (model.formulas && model.formulas.length) {
      model.formulas.forEach((formula) => {
        if (formula.length) {
          this.mainSettingsStore.setNewFormula(formula);
        }
      });
    }

    if (model.points && model.points.length) {
      if (model.output && model.output.length) {
        const parameter = model.output[0].name;
        this.displaySettingsStore.setDisplayParameter(parameter);
      }

      this.modelStore.setPoints(model.points);
    }
  }

  loadData(data){
    this.modelStore.setPoints(data);
    const parameter = [ ...this.mainSettingsStore.outputCriterias.keys()][0];
    this.displaySettingsStore.setDisplayParameter(parameter);
    console.log(this.modelStore.points, data, parameter);
  }

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.mainSettingsStore = new MainSettingsStore(this.rootStore);
    this.calculationSettingsStore = new CalculationSettingsStore(this.rootStore);
    this.optimizationSettingsStore = new OptimizationSettingsStore(this.rootStore);
    this.displaySettingsStore = new DisplaySettingsStore(this.rootStore);
    this.modelStore = new ModelStore(this.rootStore);
    makeAutoObservable(this, {}, { autoBind: true });

    /* 
      Реакция добавляет и удаляет критерии из селектов в табе 'Параметры расчетов', если пользователь изменяет их в основных настройках проекта 
    */
    reaction(() => this.mainSettingsStore.outputCriterias.size,
    () => {
      const xAxisCriteria = this.calculationSettingsStore.xAxis;
      if (!this.mainSettingsStore.outputCriterias.has(xAxisCriteria)) {
        this.calculationSettingsStore.clearXAxis();
      }

      const yAxisCriteria = this.calculationSettingsStore.yAxis;
      if (!this.mainSettingsStore.outputCriterias.has(yAxisCriteria)) {
        this.calculationSettingsStore.clearYAxis();
      }
    });
  }
}