import { createContext, useContext } from "react";
import { rootStore } from "./root-store";

const RootContext = createContext(rootStore);

export const RootProvider = ({ children }) => {
  return <RootContext.Provider value={rootStore}>
    { children }
  </RootContext.Provider>;
};

export const useStores = () => {
  return useContext(RootContext);
};