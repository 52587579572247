import clsx from "clsx";
import React from "react";
import PropTypes, { string, node, element } from "prop-types";
import styles from "./Button.module.sass";

export const Button = ({ className = "", children, ...props }) => {
    return (
        <button {...props} className={clsx(styles["button"], className)}>
            {children}
        </button>
    );
};

Button.propTypes = {
    className: string,
    children: PropTypes.oneOfType([
        node,
        element,
        string
    ])
};