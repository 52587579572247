export const parseLimits = (exportData, input, output) => {
    let input_limits = [];
    let output_limits = [];
    for (let i = 0; i < exportData.length; i++) {
        let result_object = {};
        let element = exportData[i];
        if(!(element.includes("<=") || element.includes(">=")))
            throw new Error("Нет знака сравнения <= или >=");
        let condition = element.includes("<=") ? "<=" : element.includes(">=") ? ">=" : "";
        element = element.includes("<=") ? element.split("<=") : element.includes(">=") ? element.split(">=") : [];
        const regexNumber = /-?[\d\\.]+/;
        const regexValue = /-?[A-Za-z]+[A-Za-z0-9]*/;
        let inner = true;
        element.forEach((e)=>{
            if(!(regexNumber.test(e) || regexValue.test(e)))
                throw new Error("Встречена строка \"" + e + "\", которую не удалось распознать в" + element);
            if(regexValue.test(e))
                if(input.includes(e))
                    inner = "input";
                if(output.includes(e))
                    inner = "output";
        });
        if(inner === true) continue;
        
        if(element.length > 2){
            result_object = condition == ">=" ?
            { name:element[1], max: Number(element[0]), min: Number(element[2]) } :
            { name:element[1], max: Number(element[2]), min: Number(element[0]) };
        } else {
            result_object = condition == ">=" ?
                regexNumber.test(element[1]) ?
                    { name:element[0], min: Number(element[1]) } :
                    { name:element[1], max: Number(element[0]) }
                    :
                regexNumber.test(element[1]) ?
                    { name:element[0], max: Number(element[1]) } :
                    { name:element[1], min: Number(element[0]) };
        }
        if(inner == "input")
            input_limits.push(result_object);
        if(inner == "output")
            output_limits.push(result_object);
    }

    input.forEach(e=>{
        if(!input_limits.map(elem=>elem.name).includes(e))
            input_limits.push({ name:e });
    });

    output.forEach(e=>{
        if(!output_limits.map(elem=>elem.name).includes(e))
            output_limits.push({ name:e });
    });

    return {
        input: input_limits,
        output: output_limits
    };
};