import clsx from "clsx";
import { React } from "react";
import { Heading } from "../../../base/heading/Heading";
import { Example } from "../example/Example";
import PropTypes, { string, node, element } from "prop-types";
import styles from "./Article.module.sass";
import linkStyles from "../../../../assets/styles/components/Link.module.sass";
import { BASE_URL } from "../../../../core/api";

export const Article = ({ className = "", article, ...props }) => {
    const fileSrc = `${BASE_URL}${article.path}`;
    const imageSrc = article.image_path ? `${BASE_URL}${article.image_path}` : null;

    return(
        <section className={clsx(styles["article"])}>
            <div className={clsx(styles["info"])}>
                <div className={clsx(styles["content"])}>
                    <Heading className={clsx(styles["heading"])} level={2}>
                        { article.name }
                    </Heading>
                    {
                        (article.authors || article.published) &&
                        <div className={clsx(styles["text"])}>
                            { article.authors }
                            <br />
                            { article.published }
                        </div>
                    }
                    <div className={clsx(styles["text"])}>
                        { article.description }
                    </div>
                    {
                        article.path &&
                        <a download target="_blank" href={fileSrc} className={clsx(linkStyles["link"], styles["article-link"])} rel="noopener noreferrer">
                            Получить .pdf
                        </a>
                    }
                    
                </div>
                {
                    imageSrc &&
                    <div className={clsx(styles["image"])}>
                        <img width="200" height="200" src={imageSrc} alt="Иллюстрация статьи" />
                    </div>
                }
            </div>
            {
                article.examples &&
                <div className={clsx(styles["examples"])}>
                    <Heading level={3}>Примеры</Heading>
                    {
                        article.examples.map((example, index) => (
                            <div key={index} className={clsx(styles["example"])}>
                                <Example example={example} />
                            </div>
                        ))
                    }
                </div>
            }
        </section>
    );
};

Article.propTypes = {
    className: string,
    children: PropTypes.oneOfType([
        node,
        element,
        string
    ]),
    imageSrc: PropTypes.string,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired
};