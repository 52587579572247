// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Media parameters */
/* Colors */
/* Fonts */
.Heading_heading__fILl0 {
  position: relative;
  margin: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: "Roboto Mono", "Arial", monospace;
  color: #333333;
}
.Heading_heading-has-underline__htILg::after {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #8000FF;
}

.Heading_heading-1__td18m {
  font-size: 48px;
  line-height: 150%;
  color: #8000FF;
}

.Heading_heading-2__e\\+fqu {
  font-size: 26px;
  line-height: 150%;
}

.Heading_heading-3__17hkC {
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.sass","webpack://./src/components/base/heading/Heading.module.sass"],"names":[],"mappings":"AAAA,qBAAA;AASA,WAAA;AAWA,UAAA;AChBA;EACI,kBAAA;EACA,SAAA;EACA,uBAAA;EAAA,kBAAA;EACA,8CDaS;ECZT,cDES;ACFb;AAEI;EACI,kBAAA;EACA,cAAA;EACA,WAAA;EACA,WAAA;EACA,WAAA;EACA,yBDJC;ACIT;;AAGA;EACI,eAAA;EACA,iBAAA;EACA,cDVK;ACUT;;AAEA;EACI,eAAA;EACA,iBAAA;AACJ;;AACA;EACI,eAAA;EACA,iBAAA;EACA,gBAAA;AAEJ","sourcesContent":["/* Media parameters */\r\n$mobile-max: 768px\r\n$tablet-max: 1210px\r\n$desktop-max: 1440px\r\n\r\n// Отступы от краев экрана\r\n$mobile-offset: 8px\r\n$desktop-offset: 64px\r\n\r\n/* Colors */\r\n$base-white: #FFFFFF\r\n$base-black: #333333\r\n$base-shadow: #0500FF40\r\n$accent: #8000FF\r\n$accent-darken: #6000BF\r\n$link: #007AFF\r\n$error: #D60056\r\n// Также отвечает и за disabled\r\n$placeholder: #33333360\r\n\r\n/* Fonts */\r\n$fm-primary: 'Roboto Mono', 'Arial', monospace","@use \"../../../assets/styles/variables\" as *\r\n@use \"../../../assets/styles/mixins\" as mix\r\n\r\n\r\n.heading\r\n    position: relative\r\n    margin: 0\r\n    width: fit-content\r\n    font-family: $fm-primary\r\n    color: $base-black\r\n\r\n    &-has-underline::after\r\n        position: absolute\r\n        display: block\r\n        content: \"\"\r\n        width: 100%\r\n        height: 1px\r\n        background-color: $accent\r\n\r\n\r\n.heading-1\r\n    font-size: 48px\r\n    line-height: 150%\r\n    color: $accent\r\n\r\n.heading-2\r\n    font-size: 26px\r\n    line-height: 150%\r\n\r\n.heading-3\r\n    font-size: 20px\r\n    line-height: 120%\r\n    font-weight: 500"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `Heading_heading__fILl0`,
	"heading-has-underline": `Heading_heading-has-underline__htILg`,
	"heading-1": `Heading_heading-1__td18m`,
	"heading-2": `Heading_heading-2__e+fqu`,
	"heading-3": `Heading_heading-3__17hkC`
};
export default ___CSS_LOADER_EXPORT___;
