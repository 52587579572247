// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Media parameters */
/* Colors */
/* Fonts */
.Editor_main__aiMws {
  display: flex;
  position: relative;
}

.Editor_view-area__2ohFM {
  display: flex;
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.sass","webpack://./src/pages/editor/Editor.module.sass"],"names":[],"mappings":"AAAA,qBAAA;AASA,WAAA;AAWA,UAAA;AClBA;EACI,aAAA;EACA,kBAAA;AAEJ;;AACA;EACI,aAAA;EACA,YAAA;AAEJ","sourcesContent":["/* Media parameters */\r\n$mobile-max: 768px\r\n$tablet-max: 1210px\r\n$desktop-max: 1440px\r\n\r\n// Отступы от краев экрана\r\n$mobile-offset: 8px\r\n$desktop-offset: 64px\r\n\r\n/* Colors */\r\n$base-white: #FFFFFF\r\n$base-black: #333333\r\n$base-shadow: #0500FF40\r\n$accent: #8000FF\r\n$accent-darken: #6000BF\r\n$link: #007AFF\r\n$error: #D60056\r\n// Также отвечает и за disabled\r\n$placeholder: #33333360\r\n\r\n/* Fonts */\r\n$fm-primary: 'Roboto Mono', 'Arial', monospace","@use \"../../assets/styles/variables\" as *\r\n\r\n.main\r\n    display: flex\r\n    position: relative\r\n    //background-color: $placeholder\r\n\r\n.view-area\r\n    display: flex\r\n    flex-grow: 1"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Editor_main__aiMws`,
	"view-area": `Editor_view-area__2ohFM`
};
export default ___CSS_LOADER_EXPORT___;
