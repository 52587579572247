// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Axis_wrapper__0dWwa {
  display: flex;
  flex-direction: column;
}

.Axis_select__ZBz7H {
  flex-grow: 1;
}

.Axis_axis-name__Bb\\+6L {
  margin-bottom: 10px;
}

.Axis_row__3XzyI {
  display: flex;
  align-items: center;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/base/axis/Axis.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,mBAAA;AAGF;;AADA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AAIF","sourcesContent":[".wrapper\r\n  display: flex\r\n  flex-direction: column\r\n\r\n.select\r\n  flex-grow: 1\r\n\r\n.axis-name\r\n  margin-bottom: 10px\r\n\r\n.row\r\n  display: flex\r\n  align-items: center\r\n  gap: 8px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Axis_wrapper__0dWwa`,
	"select": `Axis_select__ZBz7H`,
	"axis-name": `Axis_axis-name__Bb+6L`,
	"row": `Axis_row__3XzyI`
};
export default ___CSS_LOADER_EXPORT___;
