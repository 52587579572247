import { React } from "react";
import { number, string } from "prop-types";

export const Logo = ({ alt, adaptiveSize = null, size = 32, className }) => {
  const logos = require.context("../../../assets/images/logo", true);

  return (
    <picture>
      {
        adaptiveSize &&
        <source media="(max-width: 768px)" srcSet={logos(`./logo-${adaptiveSize}.svg`)} width={adaptiveSize} height={adaptiveSize}/>
      }
      <img className={className} src={logos(`./logo-${size}.svg`)} height={size} width={size} alt={alt} />
    </picture>
  );
};

Logo.propTypes = {
  alt: string,
  adaptiveSize: number,
  size: number
};