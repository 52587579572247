// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainProjectSettings_main-settings__F7f2n {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
}

.MainProjectSettings_main-setting-panel__XnbMf {
  display: flex;
  flex-direction: column;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/editor/mainProjectSettings/MainProjectSettings.module.sass"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;AAAF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF","sourcesContent":["\r\n.main-settings\r\n  display: flex\r\n  flex-direction: column\r\n  gap: 16px\r\n  flex-grow: 1\r\n\r\n.main-setting-panel\r\n  display: flex\r\n  flex-direction: column\r\n  gap: 8px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-settings": `MainProjectSettings_main-settings__F7f2n`,
	"main-setting-panel": `MainProjectSettings_main-setting-panel__XnbMf`
};
export default ___CSS_LOADER_EXPORT___;
