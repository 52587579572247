import clsx from "clsx";
import { React } from "react";
import PropTypes, { bool, number, string, node, element } from "prop-types";
import styles from "./Heading.module.sass";

export const Heading = ({ level = 1, levelClass, className = "", underline = false, children = null }) => {
  const Tag = `h${level}`;

  if (!levelClass) {
    levelClass = `heading-${level}`;
  }

  return (
    <Tag className={clsx(styles["heading"], styles[levelClass], className, { [styles["heading-has-underline"]]: underline })}>
      { children }
    </Tag>
  );
};

Heading.propTypes = {
  level: number,
  levelClass: number,
  className: string,
  underline: bool,
  children: PropTypes.oneOfType([
    node,
    element,
    string
  ])
};