import "./assets/styles/base.sass";
import { router } from "./pages/router";
import { React } from "react";
import { RouterProvider } from "react-router-dom";
import { RootProvider } from "./stores/root-store-context";

function App() {
  return (
    <RootProvider>
      <RouterProvider router={router}/>
    </RootProvider>
  );
}

export default App;
