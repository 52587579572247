import { observer } from "mobx-react-lite";
import { Heading } from "../../../base/heading/Heading";
import { useStores } from "../../../../stores/root-store-context";
import { Select, SelectOption } from "../../../form/newSelect/Select";
import { DISPLAY_TYPES } from "../../../../stores/display-settings-store";
import { values } from "mobx";
import styles from "./DisplaySettings.module.sass";
import { clsx } from "clsx";

export const DisplaySettings = observer(() => {
  const {
    projectStore: {
      displaySettingsStore: {
        displayType,
        setDisplayType,
        displayParameter,
        setDisplayParameter
      },
      mainSettingsStore: { outputCriterias }
    }
  } = useStores();

  return (
    <div className={clsx(styles["wrapper"])}>
      <Heading level={2}>
        Параметры вывода данных
      </Heading>
      <div>
        <Heading level={3}>
          Вид
        </Heading>
          <Select
          selectedOption={displayType}
          setOption={(value) => { setDisplayType(value); }}
          >
            {
              DISPLAY_TYPES.map((type) => (
                <SelectOption value={type}/>
              ))
            }
        </Select>
      </div>
      <div>
          <Heading level={3}>
            Отображаемый критерий
          </Heading>
          <Select
            selectedOption={displayParameter}
            setOption={(value) => { setDisplayParameter(value); }}
          >
            {
              values(outputCriterias).map((criteria) => (
                <SelectOption value={criteria}/>
              ))
            }
          </Select>
      </div>
    </div>
  );
});