import React from "react";
import { Heading } from "../../../base/heading/Heading";
import { Select, SelectOption } from "../../../form/newSelect/Select";
import { Input } from "../../../form/input/Input";
import clsx from "clsx";
import styles from "./OptimizationSettings.module.sass";
import { useStores } from "../../../../stores/root-store-context";
import { START_OPTIONS, VALUE_TYPE_OPTIONS } from "../../../../stores/optimization-settings-store";
import { observer } from "mobx-react-lite";

export const OptimizationSettings = observer(() => {
  const {
    projectStore: {
      optimizationSettingsStore: {
        regionCenter,
        setRegionCenter,
        regionSize,
        setRegionSize,
        firstPointOnGrid,
        setFirstPointOnGrid,
        internalGridNotes,
        setInternalGridNotes
      }
    }
  } = useStores();

    return (
        <div>
          <Heading level={2}>
            Параметры оптимизации
          </Heading>
          <div>
            <p>
              Данные настройки применяются для оптимизации поиска, см. руководство, прежде чем изменять значения
            </p>
            <div className={clsx(styles["setting"])}>
              <Heading level={3}>region_center</Heading>
              <Select
                selectedOption={regionCenter.startPoint}
                setOption={(option) => setRegionCenter({ ...regionCenter, startPoint: option })}
              >
                {
                  START_OPTIONS.map((option) => (
                    <SelectOption key={option} value={option} />
                  ))
                }
              </Select>
              <div className={clsx(styles["row"])}>
                <span>
                  type
                </span>
                <Select
                  selectedOption={regionCenter.value.type}
                  setOption={(type) => setRegionCenter({ ...regionCenter, value: { ...regionCenter.value, type } })}
                >
                  {
                    VALUE_TYPE_OPTIONS.map((type) => (
                      <SelectOption key={type} value={type} />
                    ))
                  }
                </Select>
              </div>
              <div className={clsx(styles["row"])}>
                <span>
                  n_point
                </span>
                <Input
                type="number"
                value={regionCenter.value.n_point}
                onChange={(e) => { setRegionCenter({ ...regionCenter, value: { ...regionCenter.value, n_point: +e.target.value } }); }} />
              </div>
              <div className={clsx(styles["row"])}>
                <span>
                  n_interation
                </span>
                <Input
                type="number"
                value={regionCenter.value.n_iteration}
                onChange={(e) => { setRegionCenter({ ...regionCenter, value: { ...regionCenter.value, n_iteration: +e.target.value } }); }} />
              </div>
            </div>
            <div className={clsx(styles["setting"])}>
              <Heading level={3}>
                region_size
              </Heading>
              <Select
                selectedOption={regionSize.startPoint}
                setOption={(option) => setRegionSize({ ...regionSize, startPoint: option })}
              >
                {
                  START_OPTIONS.map((option) => (
                    <SelectOption key={option} value={option} />
                  ))
                }
              </Select>
            </div>
            <div className={clsx(styles["setting"])}>
              <Heading level={3}>
                first_point_on_grid
              </Heading>
              <Select
                selectedOption={firstPointOnGrid.startPoint}
                setOption={(option) => setFirstPointOnGrid({ ...firstPointOnGrid, startPoint: option })}
              >
              {
                  START_OPTIONS.map((option) => (
                    <SelectOption key={option} value={option} />
                  ))
                }
              </Select>
              <div className={clsx(styles["row"])}>
                <span>
                  type
                </span>
                <Select
                  selectedOption={firstPointOnGrid.value.type}
                  setOption={(type) => setFirstPointOnGrid({ ...firstPointOnGrid, value: { ...firstPointOnGrid.value, type } })}
                >
                  {
                    VALUE_TYPE_OPTIONS.map((type) => (
                      <SelectOption key={type} value={type} />
                    ))
                  }
                </Select>
              </div>
              <div className={clsx(styles["row"])}>
                <span>
                  n_point
                </span>
                <Input
                  type="number"
                  value={firstPointOnGrid.value.n_point}
                  onChange={(e) => { setFirstPointOnGrid({ ...firstPointOnGrid, value: { ...firstPointOnGrid.value, n_point: +e.target.value } }); }}
                />
              </div>
              <div className={clsx(styles["row"])}>
                <span>
                  n_interation
                </span>
                <Input
                  type="number"
                  value={firstPointOnGrid.value.n_iteration}
                  onChange={(e) => { setFirstPointOnGrid({ ...firstPointOnGrid, value: { ...firstPointOnGrid.value, n_iteration: +e.target.value } }); }}
                />
              </div>
            </div>
            <div className={clsx(styles["setting"])}>
              <Heading level={3}>
                internal_grid_notes
              </Heading>
              <Select
                selectedOption={internalGridNotes.startPoint}
                setOption={(option) => setInternalGridNotes({ ...internalGridNotes, startPoint: option })}
              >
              {
                  START_OPTIONS.map((option) => (
                    <SelectOption key={option} value={option} />
                  ))
                }
              </Select>
            </div>
          </div>
        </div>
    );
});