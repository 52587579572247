// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Constants_constant__HkMWk {
  display: flex;
  align-items: end;
  gap: 16px;
}

.Constants_constant-key__TsT2p {
  display: flex;
  padding-bottom: 8px;
}

.Constants_inputs__wst1z {
  display: flex;
  flex-direction: column;
}

.Constants_constant-input__NFsZV {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/editor/constants/Constants.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,SAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;AAEF;;AAAA;EACE,aAAA;EACA,sBAAA;AAGF;;AADA;EACE,WAAA;AAIF","sourcesContent":[".constant\r\n  display: flex\r\n  align-items: end\r\n  gap: 16px\r\n\r\n.constant-key\r\n  display: flex\r\n  padding-bottom: 8px\r\n\r\n.inputs\r\n  display: flex\r\n  flex-direction: column\r\n\r\n.constant-input\r\n  width: 100%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"constant": `Constants_constant__HkMWk`,
	"constant-key": `Constants_constant-key__TsT2p`,
	"inputs": `Constants_inputs__wst1z`,
	"constant-input": `Constants_constant-input__NFsZV`
};
export default ___CSS_LOADER_EXPORT___;
