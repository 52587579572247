import clsx from "clsx";
import { React, useEffect, useState } from "react";
import { Article } from "../../components/pages/articles/article/Article";
import { Pagination } from "../../components/base/pagination/Pagination";
import styles from "./Articles.module.sass";
import { fetchData } from "../../core/api";

const mockArticles = [
  {
    id: "himmel",
    title: "Функция Химмельблау",
    description: "Визуализация поверхности функции химмельблау.",
    examples: [
      {
        name: "Открыть в редакторе",
        src: "Himmel.json"
      }
    ]
  }
];

export const Articles = () => {
  const [pageData, setPageData] = useState(null);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    const getArticles = async () => {
      const url = `/articles/articles.php?page=${activePage}&limit=5`;
      try {
        const pageData = await fetchData(url);
        setPageData(pageData);
      } catch (e) {
        console.log(e);
      }
    };

    getArticles();
  }, [activePage]);

  return (
    <div className={styles["articles"]}>
      <main>
        {
          pageData &&
          <>
            <div className={styles["articleList"]}>
              {pageData.data.map((article) =>
                  <Article article={article}/>
              )}
            </div>
            <div className={clsx(styles["pagination-container"])}>
              <Pagination value={activePage} onChange={setActivePage} totalPages={pageData.totalPagesNumber}/>
            </div>
          </>
        }
      </main>
    </div>
  );
};